import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarMenuItem from '../SideBarMenuItem';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';

const SideBarCartMenu = observer(() => {
    const { t } = useTranslation();
    const { configurationStore } = useStores();

    const isCartsMenuEnabled = configurationStore.isCartWitnessingFeatureEnabled();
    if (isCartsMenuEnabled) {
        return (
            <Can do="read" on='Cart'>
                <SideBarMenuItem id="side_menu_item_carts" to='/carts' text={t("carts")}>
                    <DocumentScannerOutlinedIcon />
                </SideBarMenuItem>
            </Can>
        )
    } else return null;
});

export default SideBarCartMenu;