import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useStores } from '../../stores';
import moment from 'moment';
import { congregationStateTypes } from './congregationStateUtils';

const CongregationStateChartTab = () => {
    const { t } = useTranslation();
    const { serverStore, congregationStateStore } = useStores();
    const [type, setType] = React.useState<string>("publishers");
    const [state, setState] = React.useState<any[]>([]);
    const [currentState, setCurrentState] = React.useState<any[]>([]);

    React.useEffect(() => {
        congregationStateStore.getCongregationState((state: any[]) => {
            setState(state);
        });
    }, [congregationStateStore]);

    React.useEffect(() => {
        const curState = state.map(elem => ({
            date: moment(elem.date).format("MM-YYYY"),
            value: elem[type]
        }))
        curState.sort((a, b) => moment(a.date, "MM-YYYY").valueOf() - moment(b.date, "MM-YYYY").valueOf());
        setCurrentState(curState);
    }, [state, type]);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ height: "80vh" }}>
                {serverStore.serverRequestLoading && <LinearProgress />}
                <FormControl sx={{ m: 1 }}>
                    <InputLabel>{t("type")}</InputLabel>
                    <Select
                        value={type}
                        label={t("type")}
                        onChange={(event: SelectChangeEvent) => setType(event.target.value)}
                    >
                        {congregationStateTypes.map(type => <MenuItem key={type.name} value={type.value}>{t(type.name)}</MenuItem>)}
                    </Select>
                </FormControl>

                <ResponsiveContainer width="100%" height="80%">
                    <LineChart
                        data={currentState}
                        margin={{ top: 5, bottom: 15, }}
                    >
                        <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
                        <YAxis padding={{ top: 30, bottom: 30 }} type="number" allowDataOverflow={true} domain={['dataMin - 10', 'dataMax + 10']} />
                        <Tooltip formatter={(value: number) => value} />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Line dataKey="value" name={t("amount")} stroke="#8884d8" />
                    </LineChart >
                </ResponsiveContainer>
            </Paper>
        </Container>
    );
};

export default CongregationStateChartTab;