import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

interface Props {
    key: string;
    value: string;
    options?: JSX.Element[];
    stringOptions?: string[];
    onChange: (value: string) => void;
}

const PanelHeaderSelect = (props: Props) => {
    const { t } = useTranslation();

    const handleSortChange = (event: SelectChangeEvent) => {
        props.onChange(event.target.value);
    };

    const groupDropdownStyle = () => {
        const style = {
            backgroundColor: "#FFFFFFCC",
            borderRadius: "10px",
            height: 40
        } as any;
        if (isMobile) {
            style.height = 35;
            style.fontSize = 13;
        }
        return style;
    }

    return (
        <Select
            key={props.key}
            style={groupDropdownStyle()}
            value={props.value}
            onChange={handleSortChange}
        >
            {props.options && props.options}
            {props.stringOptions && props.stringOptions.map(option => <MenuItem key={option} value={option}>{t(option)}</MenuItem>)}
        </Select>
    )
};

export default PanelHeaderSelect;