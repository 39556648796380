import { useFormik } from 'formik';
import { Button, Dialog, FormLabel, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, TextField, Divider, Box, IconButton } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Publisher, ServiceAssignment } from '../../../domain/Publisher';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FieldServiceReport } from '../../../domain/FieldServiceReport';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { getLocale } from '../../../utils/localeUtils';
import { useStores } from '../../../stores';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';
import { useConfirm } from 'material-ui-confirm';

interface Props {
    isOpen: boolean;
    publisher: Publisher;
    report?: FieldServiceReport;
    onSubmit?: () => void;
    onDelete?: () => void;
    onClose: () => void;
}

const FieldServiceReportDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { reportStore } = useStores();
    const confirm = useConfirm();

    let formData = {
        date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        empty: false,
    } as FieldServiceReport;
    if (props.report) {
        formData = {
            ...formData,
            ...props.report
        }
    }
    if (!formData.serviceAssignment) {
        formData.serviceAssignment = undefined;
    }

    const schema = yup.object().shape({
        date: yup.date().required(t("field_is_required", { name: t("date") })),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: formData,
        onSubmit: (report, { resetForm }) => {
            const updatedReport = {
                ...report,
                publisherId: props.publisher.id,
                hours: report.hours || 0,
                bibleStudies: report.bibleStudies || 0,
                empty: report.empty
            } as FieldServiceReport;
            if (updatedReport.serviceAssignment === undefined) {
                delete updatedReport.serviceAssignment;
            }

            const update = props.report ? !!props.report.id : false;
            if (update) {
                reportStore.updateS4Report(updatedReport, () => {
                    props.onSubmit && props.onSubmit();
                });
            } else {
                reportStore.createS4Report(updatedReport, () => {
                    props.onSubmit && props.onSubmit();
                });
            }
            resetForm();
            props.onClose();
        }
    });

    const handleReportDelete = () => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            reportStore.deleteReport(props.report!.id!, props.publisher.id, () => {
                props.onDelete && props.onDelete();
                props.onClose();
            });
        });
    }

    const showDeleteBtn = !!props.report && !!props.onDelete;

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    {!!props.report && <CheckCircleOutlinedIcon sx={{ marginRight: 1 }} />}
                    {t("field_service_report")}
                </Box>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("date")}
                            views={['year', 'month']}
                            maxDate={new Date()}
                            value={values.date}
                            disableMaskedInput
                            onChange={value => {
                                formik.setFieldValue('date', value ? moment(value).format('YYYY-MM-DD') : value);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!errors.date}
                                    helperText={errors.date && errors.date as string}
                                    name="date"
                                />}
                        />
                    </LocalizationProvider>

                    <Grid container>
                        {values.serviceAssignment ?
                            <Grid item xs={6} padding={1}>
                                <TextField
                                    margin="normal"
                                    id="hours"
                                    label={t("hours")}
                                    type="number"
                                    variant="standard"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={values.hours || ''}
                                    onChange={formik.handleChange}
                                /></Grid>
                            :
                            <Grid item xs={6} padding={1}>
                                <FormLabel>{t("was_preaching")}</FormLabel>
                                <RadioGroup
                                    row
                                    id="empty"
                                    value={values.empty}
                                    onChange={(e, v) => {
                                        const isEmpty = v === "true";
                                        formik.setFieldValue('empty', isEmpty);
                                        if (isEmpty) {
                                            formik.setFieldValue('hours', '');
                                            formik.setFieldValue('bibleStudies', '');
                                        }
                                    }}
                                >
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        checked={!values.empty}
                                        label={t("yes")} />
                                    <FormControlLabel
                                        value={true}
                                        checked={values.empty}
                                        control={<Radio />}
                                        label={t("no")} />
                                </RadioGroup>
                            </Grid>}
                        <Grid item xs={6} padding={1}>
                            {!values.empty && <TextField
                                margin="normal"
                                id="bibleStudies"
                                label={t("bible_studies")}
                                type="number"
                                variant="standard"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={values.bibleStudies || ''}
                                onChange={formik.handleChange}
                            />}
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container>
                        <Grid item padding={1}>
                            <FormLabel>{t("service_assignment")}</FormLabel>
                            <RadioGroup
                                name="serviceAssignment"
                                value={values.serviceAssignment}
                                onChange={(e, v) => {
                                    formik.setFieldValue('empty', false);
                                    formik.setFieldValue('serviceAssignment', v);
                                    const notes = v === ServiceAssignment.AuxiliaryPioneer ? t("auxiliary_pioneer_short") : "";
                                    formik.setFieldValue('notes', notes);
                                }}
                            >
                                {Object.values(ServiceAssignment)
                                    .map(assign => (
                                        <FormControlLabel
                                            key={assign}
                                            value={assign}
                                            checked={values.serviceAssignment && values.serviceAssignment === assign}
                                            control={<Radio size='small' />}
                                            label={t(assign)}
                                        />))}
                            </RadioGroup>
                        </Grid>
                    </Grid>

                    <Divider />

                    <TextField
                        margin="normal"
                        id="notes"
                        label={t("notes")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={values.notes || ''}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions sx={{ justifyContent: showDeleteBtn ? "space-between" : "flex-end" }}>
                    {showDeleteBtn && (
                        <IconButton onClick={handleReportDelete}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    )}
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Button onClick={props.onClose}>{t("cancel")}</Button>
                        <Button type="submit">{t("submit")}</Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(FieldServiceReportDialog);