import * as React from 'react';
import { useStores } from '../../../stores';
import { TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Button, Dialog, DialogActions, DialogContent, DialogTitle, TableSortLabel } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    publisherId?: number;
    onClose: () => void;
}

const PublisherScheduleDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { scheduleStore } = useStores();
    const [dateSort, setDateSort] = React.useState<boolean>(false);
    const [entries, setEntries] = React.useState<ScheduleEntry[]>([]);

    React.useEffect(() => {
        if (props.isOpen) {
            scheduleStore.getEntriesByPublisher(props.publisherId!, (e: ScheduleEntry[]) => {
                setEntries(e.filter(entry => entry.subType !== "assignment"));
            });
        }
    }, [props.publisherId, props.isOpen, scheduleStore]);

    const handleDateHeaderClick = () => {
        setDateSort(!dateSort);
    }

    const getRole = (entry: ScheduleEntry) => {
        if (entry.speakerId === props.publisherId) {
            return t("speaker");
        } else if (entry.companionId === props.publisherId) {
            return t("companion");
        } else if (entry.impromptuId === props.publisherId) {
            return t("impromptu");
        } else return "";
    }

    return (
        <Dialog maxWidth="lg" open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("schedule")}</DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={true}
                                        direction={dateSort ? 'asc' : 'desc'}
                                        onClick={handleDateHeaderClick}>
                                        {t("date")}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t("name")}</TableCell>
                                <TableCell>{t("room")}</TableCell>
                                <TableCell>{t("role")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries && entries
                                .sort((entry1, entry2) => {
                                    const week1 = entry1.week;
                                    const week2 = entry2.week;
                                    if (week1 && week2) {
                                        const date1 = dateSort ? week2.date : week1.date;
                                        const date2 = dateSort ? week1.date : week2.date;
                                        return new Date(date1).getTime() - new Date(date2).getTime();
                                    }
                                    return 0;
                                })
                                .map((entry: ScheduleEntry) => (
                                    <TableRow key={entry.id}>
                                        <TableCell>{moment(entry.week!.date).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell>{entry.name}</TableCell>
                                        <TableCell>{entry.room}</TableCell>
                                        <TableCell>{getRole(entry)}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );
});

export default withLazyMounting(PublisherScheduleDialog);