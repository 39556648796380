import * as React from 'react';
import { useStores } from '../../../../stores';
import { Territory } from '../../../../domain/Territory';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import TerritoryAssignmentTable from '../table/TerritoryAssignmentTable';
import { Paper, TableContainer } from '@mui/material';

interface Props {
    onTerritoryShow: (territory: Territory) => void;
    onTerritoryReturn: (territory: Territory) => void;
    onAssignmentEdit: (assignment: TerritoryAssignment) => void;
}

const CurrentTerritoryAssignmentsSortedTable = (props: Props) => {
    const { territoryStore } = useStores();
    const [assignments, setAssignments] = React.useState<TerritoryAssignment[]>([]);

    React.useEffect(() => {
        territoryStore.getActiveAssignments((data) => {
            setAssignments(data);
        })
    }, [territoryStore]);

    return (
        <TableContainer component={Paper}>
            <TerritoryAssignmentTable
                assignments={assignments}
                onTerritoryShow={props.onTerritoryShow}
                onTerritoryAssign={() => {}}
                onAssignmentEdit={props.onAssignmentEdit}
            />
        </TableContainer>
    );
};

export default CurrentTerritoryAssignmentsSortedTable;