export const congregationStateTypes = [
    { name: "publishers", value: "publishers" },
    { name: "elders", value: "elders" },
    { name: "ministerial_servants", value: "servants" },
    { name: "baptised_publishers", value: "baptisedPublishers" },
    { name: "anointed_publishers", value: "anointedPublishers" },
    { name: "active_publishers", value: "activePublishers" },
    { name: "inactive_publishers", value: "inactivePublishers" },
    { name: "brothers", value: "brothers" },
    { name: "sisters", value: "sisters" },
    { name: "average_age", value: "averageAge" },
    { name: "average_baptised", value: "averageBaptised" },
    { name: "pioneers", value: "regularPioneers" },
    { name: "auxiliary_pioneers", value: "auxiliaryPioneers" },
    { name: "special_pioneers", value: "specialPioneers" },
    { name: "bible_studies", value: "bibleStudies" },
    { name: "submitted_reports", value: "submittedReports" },
    { name: "no_report", value: "emptyReports" },
    { name: "service_groups", value: "serviceGroups" },
    { name: "accounting_overall_amount", value: "accountingOverallAmount" },
    { name: "accounting_income", value: "accountingIncome" },
    { name: "accounting_expenses", value: "accountingExpenses" },
    { name: "average_attendance", value: "averageAttendance" },
    { name: "literature_requested_overall", value: "literatureRequestedOverall" },
    { name: "territories", value: "territories" },
    { name: "territories_taken", value: "territoriesTaken" },
    { name: "territories_returned", value: "territoriesReturned" },
    { name: "students", value: "students" },
    { name: "cart_records", value: "cartRecords" },
    { name: "cart_publishers_approved", value: "cartPublishersApproved" },
    { name: "cart_publishers_served", value: "cartPublishersServed" },
    { name: "users", value: "users" },
];