import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Role, User } from '../../domain/User';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { Publisher } from '../../domain/Publisher';
import { mainUserRoles } from './userRoleUtils';

interface Props {
    users: User[];
    publishers: Publisher[];
    onUpdateUser: (user: User) => void;
    onDeleteUser: (user: User) => void;
    onPushNotificationSend: (user: User) => void;
}

const UsersTable = (props: Props) => {
    const { t } = useTranslation();

    const getPublisherName = (publisherId?: number): string => {
        const publisher = props.publishers.find(p => p.id === publisherId);
        if (publisher) {
            return `${publisher.lastName} ${publisher.firstName}`;
        } else return '';
    }

    const mainRole = (roles: Role[]) => roles
        .map(role => role.name)
        .find(role => mainUserRoles.includes(role))!;

    const roles = (roles: Role[]) => roles
        .filter(role => !mainUserRoles.includes(role.name)).map(role => t(role.name)).join(", ");

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{t("username")}</TableCell>
                        <TableCell>{t("type")}</TableCell>
                        <TableCell>{t("roles")}</TableCell>
                        <TableCell>{t("publisher")}</TableCell>
                        <Can do="manage" on='User'>
                            <TableCell>{t("actions")}</TableCell>
                        </Can>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.users.map((user: User, i: number) => (
                        <TableRow key={user.username}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{t(mainRole(user.roles))}</TableCell>
                            <TableCell>{roles(user.roles)}</TableCell>
                            <TableCell>{getPublisherName(user.publisherId)}</TableCell>
                            <Can do="manage" on='User'>
                                <TableCell>
                                    <IconButton onClick={() => props.onUpdateUser(user)}>
                                        <EditIcon color="primary" />
                                    </IconButton>
                                    <Can do="manage" on='Admin'>
                                        {user.webPushSubscriptionId && <IconButton onClick={() => props.onPushNotificationSend(user)}>
                                            <SendIcon color="primary" />
                                        </IconButton>}
                                    </Can>
                                    <IconButton onClick={() => props.onDeleteUser(user)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            </Can>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UsersTable;