import * as React from 'react';
import { Breakpoint, Container, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../../stores';
import { Territory } from '../../../../domain/Territory';
import DesktopMyTerritoriesTableRow from './DesktopMyTerritoriesTableRow';
import { getShapeCenter, stringToLatLng } from '../../mapUtils';
import ReturnTerritoryDialog from '../../dialogs/ReturnTerritoryDialog';
import { useOpenDialog } from '../../../../hooks/useOpenDialog';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';

interface Props {
    size: Breakpoint;
}

const DesktopMyTerritoriesContent = observer((props: Props) => {
    const { t } = useTranslation();
    const { serverStore, territoryStore, publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [myAssignments, setMyAssignments] = React.useState<TerritoryAssignment[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();

    React.useEffect(() => {
        territoryStore.getMyTerritories((data) => {
            setMyAssignments(data)
        });
    }, [territoryStore, publisherStore.myPublisher]);


    const handleTerritoryDirections = (territory: Territory) => {
        const shape = stringToLatLng(territory.boundary);
        const center = getShapeCenter(shape);
        window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`);
    }

    const openReturnTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("return-territory")
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
    }

    const handleTerritoryReturned = () => {
        territoryStore.getMyTerritories((data) => {
            setMyAssignments(data)
        });
    }

    return (
        <>
            <Container maxWidth={props.size} sx={{ mt: 4, mb: 4 }}>
                <Paper style={{ height: "80vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                    {myAssignments.length ? <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>{t("title")}</TableCell>
                                    <TableCell>{t("group")}</TableCell>
                                    <TableCell>{t("days_gone")}</TableCell>
                                    <TableCell>{t("actions")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {myAssignments
                                    .map((assignment: TerritoryAssignment) => (
                                        <DesktopMyTerritoriesTableRow
                                            key={assignment.id}
                                            assignment={assignment}
                                            onTerritoryDirections={handleTerritoryDirections}
                                            onTerritoryReturn={(territory) => openReturnTerritoryDialog(territory)}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer> : (
                        <>
                            {serverStore.serverRequestLoading && <LinearProgress />}
                            <Typography variant="h6" textAlign={'center'}>
                                {t("you_dont_have_assigned_territories")}
                            </Typography>
                        </>

                    )}
                </Paper>
            </Container>
            <ReturnTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("return-territory")}
                onClose={handleCloseDialog}
                onTerritoryReturned={handleTerritoryReturned}
            />
        </>
    );
});

export default DesktopMyTerritoriesContent;