import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { observer } from "mobx-react-lite";
import { useStores } from '../../stores';
import { useLocation, useNavigate } from 'react-router-dom';
import defineRulesFor from '../../permissions/ability';
import { AbilityContext } from '../../components/Can';
import { useTranslation } from "react-i18next";
import { Alert } from '@mui/material';

const theme = createTheme();

const LoginPage = observer(() => {
    const { t } = useTranslation();
    const { userStore, configurationStore } = useStores();
    const [message, setMessage] = React.useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const ability = React.useContext(AbilityContext);
    const congregationName = configurationStore.getCongregationName();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('username')!.toString();
        const password = data.get('password')!.toString();
        userStore.login(username, password, (result: boolean, message?: string) => {
            if (!result && message) {
                setMessage(message!)
            }
        });
    };

    React.useEffect(() => {
        if (userStore.userData.loggedIn) {
            ability.update(defineRulesFor(userStore.userData.roles));
            const state = location.state as { from: string };
            if (state && state.from) {
                navigate(state.from);
            } else {
                navigate('/');
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("sign_in")}
                    </Typography>
                    <Typography component="h1" variant="subtitle1">
                        {congregationName}
                    </Typography>
                    {userStore.userData.loggedIn && <DoneOutlineIcon />}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={t("username")}
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t("sign_in")}
                        </Button>
                        {message && <Alert severity="error">{message}</Alert>}
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
});

export default LoginPage;