import * as React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from "react-i18next";
import Panel from '../../panel/Panel';
import TerritoriesAssignmentTab from './assignments/TerritoriesAssignmentTab';
import AllTerritoryRequestsTab from './requests/AllTerritoryRequestsTab';
import TerritoryStatisticsTab from './statistics/TerritoryStatisticsTab';
import TerritoryAssignmentHistoryTab from './history/TerritoryAssignmentHistoryTab';
import PanelHeaderSelect from '../../../components/PanelHeaderSelect';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';

const TerritoriesAssignmentPage = observer(() => {
    const { t } = useTranslation();
    const { configurationStore } = useStores();
    const [tab, setTab] = React.useState(0);
    const [sort, setSort] = React.useState<"publisher" | "date">("publisher");
    const isRequestFeatureEnabled = configurationStore.isTerritoryRequestEnabled();

    const handleSortChange = (value: string) => {
        setSort(value as "publisher" | "date");
    };

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const headerItems = () => {
        if (tab === 0) {
            return [
                <PanelHeaderSelect
                    key="assignment-sort-dropdown"
                    value={sort as string}
                    onChange={handleSortChange}
                    stringOptions={["publisher", "date"]}
                />
            ]
        } else return [];
    };

    const tabMenu = [
        <Tab key="assignments" label={t("appointments")} />,
        <Tab disabled={!isRequestFeatureEnabled} key="requests" label={t("requests")} />,
        <Tab key="history" label={t("history")} />,
        <Tab key="statistics" label={t("statistics")} />
    ];

    return (
        <Panel title={t("appointments")} headerItems={headerItems()}>
            <Tabs value={tab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                {tabMenu}
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <TerritoriesAssignmentTab sort={sort} />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <AllTerritoryRequestsTab />
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <TerritoryAssignmentHistoryTab />
            </div>
            <div role="tabpanel" hidden={tab !== 3}>
                <TerritoryStatisticsTab />
            </div>
        </Panel >
    );
});

export default TerritoriesAssignmentPage;