import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import { Territory } from '../../../../domain/Territory';
import { isMobileOnly } from 'react-device-detect';
import MobileCurrentTerritoryAssignmentTable from './MobileCurrentTerritoryAssignmentTable';
import DesktopCurrentTerritoryAssignmentTable from './DesktopCurrentTerritoryAssignmentTable';
import { Publisher } from '../../../../domain/Publisher';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
    publishers: Publisher[];
    onTerritoryShow: (territory: Territory) => void;
    onTerritoryReturn: (territory: Territory) => void;
    onAssignmentEdit: (assignment: TerritoryAssignment) => void;
}

const CurrentTerritoryAssignmentTable = (props: Props) => {
    return (
        <>
            {props.publishers.map(publisher => {
                return <Accordion key={publisher.id} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography><b>{`${publisher.lastName} ${publisher.firstName}`}</b> - {publisher.territoryAssignments!.length}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isMobileOnly ? <MobileCurrentTerritoryAssignmentTable
                            publisher={publisher}
                            onShowTerritory={props.onTerritoryShow}
                            onTerritoryReturn={props.onTerritoryReturn}
                        /> :
                            <DesktopCurrentTerritoryAssignmentTable
                                publisher={publisher}
                                onShowTerritory={props.onTerritoryShow}
                                onTerritoryReturn={props.onTerritoryReturn}
                                onAssignmentEdit={props.onAssignmentEdit}
                            />}
                    </AccordionDetails>
                </Accordion>
            })}

        </>
    );
};

export default CurrentTerritoryAssignmentTable;