import * as React from 'react';
import { Container } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../stores';
import { Territory } from '../../../../domain/Territory';
import { useOpenDialog } from '../../../../hooks/useOpenDialog';
import ReturnTerritoryDialog from '../../dialogs/ReturnTerritoryDialog';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import EditAssignmentDialog from '../../dialogs/EditAssignmentDialog';
import ShowTerritoryDialog from '../../dialogs/ShowTerritoryDialog';
import CurrentTerritoryAssignmentTable from './CurrentTerritoryAssignmentTable';
import { Publisher } from '../../../../domain/Publisher';
import CurrentTerritoryAssignmentsSortedTable from './CurrentTerritoryAssignmentsSortedTable';

interface Props {
    sort: "publisher" | "date";
}

const TerritoriesAssignmentTab = observer((props: Props) => {
    const { publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | null>(null);
    const [selectedAssignment, setSelectedAssignment] = React.useState<TerritoryAssignment | null>(null);
    const [publishers, setPublishers] = React.useState<Publisher[]>([]);

    React.useEffect(() => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }, [publisherStore]);

    const openTerritoryDialog = React.useCallback((territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("show-territory");
    }, []);

    const openReturnTerritoryDialog = React.useCallback((territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("return-territory");
    }, []);

    const openEditAssignmentDialog = React.useCallback((assignment: TerritoryAssignment) => {
        setSelectedAssignment(assignment);
        openDialog("edit-assignment")
    }, []);

    const handleCloseDialog = React.useCallback(() => {
        closeDialog();
        setSelectedTerritory(null);
        setSelectedAssignment(null);
    }, []);

    const handleTerritoryReturned = React.useCallback(() => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }, [publisherStore]);


    const handleAssignmentChange = React.useCallback(() => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }, [publisherStore]);

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {props.sort === "publisher" ?
                <CurrentTerritoryAssignmentTable
                    publishers={publishers}
                    onTerritoryShow={openTerritoryDialog}
                    onTerritoryReturn={openReturnTerritoryDialog}
                    onAssignmentEdit={openEditAssignmentDialog}
                /> :
                <CurrentTerritoryAssignmentsSortedTable
                    onTerritoryShow={openTerritoryDialog}
                    onTerritoryReturn={openReturnTerritoryDialog}
                    onAssignmentEdit={openEditAssignmentDialog}
                />}

            <ShowTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("show-territory")}
                onClose={handleCloseDialog}
            />
            <ReturnTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("return-territory")}
                onClose={handleCloseDialog}
                onTerritoryReturned={handleTerritoryReturned}
            />
            <EditAssignmentDialog
                assignment={selectedAssignment!}
                isOpen={isOpen("edit-assignment")}
                onClose={handleCloseDialog}
                disableReturnedAt
                onAssignmentChange={handleAssignmentChange}
            />
        </Container>
    );
});

export default TerritoriesAssignmentTab;