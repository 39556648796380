import * as React from 'react';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarMenuCollapseItem from '../SideBarMenuCollapseItem';
import SideBarSubMenuItem from '../SideBarSubMenuItem';
import { AbilityContext } from '../../../components/Can';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';

const SideBarExtraMenu = observer(() => {
    const { t } = useTranslation();
    const { configurationStore } = useStores();
    const ability = React.useContext(AbilityContext);

    const isFilesMenuEnabled = configurationStore.isFilesFeatureEnabled();
    const isAccountingMenuEnabled = configurationStore.isAccountingFeatureEnabled();

    if (ability.can("read", "Knowledge") || ability.can("read", "Files") || ability.can("read", "Accounting")) {
        return (
            <SideBarMenuCollapseItem icon={<ControlPointIcon />} type="extra" text={t("extra")}>
                <Can do="read" on='Knowledge'>
                    <SideBarSubMenuItem id="side_menu_item_knowledge" to="/knowledge" text={t("knowledge")} />
                </Can>
                {isFilesMenuEnabled && <Can do="read" on='Files'>
                    <SideBarSubMenuItem id="side_menu_item_files" to="/files" text={t("files")} />
                </Can>}
                {isAccountingMenuEnabled && <Can do="read" on='Accounting'>
                    <SideBarSubMenuItem id="side_menu_item_accounting" to="/accounting" text={t("accounting")} />
                </Can>}
            </SideBarMenuCollapseItem>
        );
    } else return null;
});

export default SideBarExtraMenu;