import * as React from 'react';
import { Box, Collapse, IconButton, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { Territory } from '../../../../domain/Territory';
import { LatLng } from 'leaflet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DirectionsIcon from '@mui/icons-material/Directions';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import TerritoryMap from '../../map/TerritoryMap';
import { boundsId } from '../../mapUtils';
import moment from 'moment';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';

interface Props {
    assignment: TerritoryAssignment;
    onTerritoryReturn: (territory: Territory) => void;
    onTerritoryDirections: (territory: Territory) => void;
}

const DesktopMyTerritoriesTableRow = (props: Props) => {
    const [open, setOpen] = React.useState(false);

    const territory = props.assignment.territory!;
    const boundary = JSON.parse(territory.boundary) as LatLng[][];
    return (
        <>
            <TableRow>
                <TableCell padding='none'>
                    <IconButton size="small" onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {territory.title}
                </TableCell>
                <TableCell>
                    {territory.territoryGroup!.name}
                </TableCell>
                <TableCell>
                    <b>{moment().diff(moment(props.assignment.takenAt), 'days')}</b>
                </TableCell>
                <TableCell padding='none'>
                    <IconButton onClick={() => props.onTerritoryDirections(territory)}>
                        <DirectionsIcon />
                    </IconButton>
                    <IconButton onClick={() => props.onTerritoryReturn(territory)}>
                        <KeyboardReturnIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Paper style={{ height: "65vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                                <TerritoryMap
                                    style={{ height: "100%", width: "100%" }}
                                    polygons={boundary.map((arr: LatLng[]) => {
                                        return {
                                            id: boundsId(arr),
                                            bounds: arr,
                                            territory: territory!
                                        }
                                    })}
                                    currentTerritory={territory}
                                />
                                <Typography component="pre">
                                    {territory.notes}
                                </Typography>
                            </Paper>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default DesktopMyTerritoriesTableRow;