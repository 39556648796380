import * as React from 'react';
import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Territory } from '../../../../domain/Territory';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import { useTranslation } from 'react-i18next';

interface Props {
    index: number;
    assignment: TerritoryAssignment;
    onTerritoryShow: (territory: Territory) => void;
    onTerritoryReturn: (territory: Territory) => void;
    onTerritoryDirections: (territory: Territory) => void;
}

const MobileMyTerritoriesTableRow = (props: Props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const territory = props.assignment.territory!;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTerritoryShow = () => {
        props.onTerritoryShow(territory);
        handleClose();
    }
    
    const handleTerritoryReturn = () => {
        props.onTerritoryReturn(territory);
        handleClose();
    }

    const handleTerritoryDirections = () => {
        props.onTerritoryDirections(territory);
        handleClose();
    }
    return (
        <TableRow>
            <TableCell>
                {props.index}
            </TableCell>
            <TableCell>
                {`${territory.title} - ${territory.territoryGroup!.name}`}
            </TableCell>
            <TableCell>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleTerritoryShow}>{t("show")}</MenuItem>
                    <MenuItem onClick={handleTerritoryReturn}>{t("return_back")}</MenuItem>
                    <MenuItem onClick={handleTerritoryDirections}>{t("directions")}</MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
};

export default MobileMyTerritoriesTableRow;