import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../stores';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { getLocale } from '../../utils/localeUtils';
import { withLazyMounting } from '../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const PivotRangeExcelDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { reportStore } = useStores();
    const [from, setFrom] = React.useState<Date>(moment().startOf('month').subtract(12, 'month').toDate());
    const [to, setTo] = React.useState<Date>(moment().startOf('month').toDate());

    const handleSubmit = () => {
        reportStore.downloadRangeExcel(from, to);
        props.onClose();
    }

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("download_excel")}</DialogTitle>
                <DialogContent>
                <Grid container>
                        <Grid item xs={6} padding={1}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                                <DatePicker
                                    label={t("from")}
                                    value={from}
                                    disableMaskedInput
                                    onChange={value => {
                                        setFrom(value!);
                                    }}
                                    renderInput={(params) => <TextField {...params} margin="normal" name="from" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                                <DatePicker
                                    label={t("to")}
                                    value={to}
                                    disableMaskedInput
                                    onChange={value => {
                                        setTo(value!);
                                    }}
                                    renderInput={(params) => <TextField {...params} margin="normal" name="to" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" onClick={handleSubmit}>{t("submit")}</Button>
                </DialogActions>
        </Dialog>
    );
});

export default withLazyMounting(PivotRangeExcelDialog);