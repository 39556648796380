import * as React from 'react';
import Panel from '../panel/Panel';
import { Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import AppointmentsTab from './AppointmentsTab';
import CongregationStateTab from './CongregationStateTab';
import CongregationStateChartTab from './CongregationStateChartTab';

const CongregationStatePage = observer(() => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Panel
            title={t("congregation_state")}
            headerItems={[]}>
            <Tabs value={tab} onChange={handleTabChange}>
                <Tab label={t("statistics")} />
                <Tab label={t("graph")} />
                <Tab label={t("appointments")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <CongregationStateTab />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <CongregationStateChartTab />
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <AppointmentsTab />
            </div>
        </Panel >
    );
});

export default CongregationStatePage;