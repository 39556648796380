import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { CongregationEvent } from '../domain/CongregationEvent';
import { ServerStore } from './ServerStore';

export class CongregationEventsStore{
    rootStore: RootStore;
    serverStore: ServerStore;
    events: CongregationEvent[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getEvents = (showAll?: boolean) => {
        let url = '/congregation-event';
        if (showAll) {
            url = `${url}?all=true`
        }
        this.serverStore.get(url, action((data) => {
            this.events = data;
        }));
    }

    createEvent = (data: any) => {
        this.serverStore.post('/congregation-event', data, action(() => {
            this.getEvents();
        }));
    }

    updateEvent = (id: number, data: any) => {
        this.serverStore.patch(`/congregation-event/${id}`, data, action(() => {
            this.getEvents();
        }));
    }

    deleteEvent = (id: number) => {
        this.serverStore.delete(`/congregation-event/${id}`, action(() => {
            this.getEvents();
        }));
    }
}