import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

export class ConfigurationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    getCongregationName = (): string => {
        const name = process.env.REACT_APP_CONGREGATION_NAME;
        return !!name ? name : "";
    }

    isAccountingFeatureEnabled = (): boolean => {
        return process.env.REACT_APP_ACCOUNTING_FEATURE_ENABLED === "true";
    }

    getAccountingCounterpartyOptions = (): string[] => {
        const options = process.env.REACT_APP_ACCOUNTING_COUNTERPARTY_OPTIONS;
        return !!options ? options.split(",") : [];
    }

    getAccountingCodes = (): string[] => {
        const codes = process.env.REACT_APP_ACCOUNTING_CODE_OPTIONS;
        return !!codes ? codes.split(",") : [];
    }

    isTerritoryFeatureEnabled = (): boolean => {
        return process.env.REACT_APP_TERRITORIES_FEATURE_ENABLED === "true";
    }

    isTerritoryRequestEnabled = (): boolean => {
        return process.env.REACT_APP_TERRITORIES_REQUEST_ENABLED === "true";
    }

    isTerritoryIntegrationEnabled = (): boolean => {
        return process.env.REACT_APP_TERRITORIES_INTEGRATION_FEATURE_ENABLED === "true";
    }

    getTerritoryMapDefaultLocation = (): string => {
        const location = process.env.REACT_APP_TERRITORIES_DEFAULT_LOCATION;
        return !!location ? location : "";
    }

    isCartWitnessingFeatureEnabled = (): boolean => {
        return process.env.REACT_APP_CART_WITNESSING_FEATURE_ENABLED === "true";
    }

    isLiteratureFeatureEnabled = (): boolean => {
        return process.env.REACT_APP_LITERATURE_FEATURE_ENABLED === "true";
    }

    isFilesFeatureEnabled = (): boolean => {
        return process.env.REACT_APP_FILES_FEATURE_ENABLED === "true";
    }

    isWebPushFeatureEnabled = (): boolean => {
        return process.env.REACT_APP_WEB_PUSH_FEATURE_ENABLED === "true";
    }

    getWebPushVapidKey = (): string | undefined => {
        return process.env.REACT_APP_VAPID_PUBLIC_KEY;
    }


}