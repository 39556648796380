import { Container, Paper, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const DisabledFeature = () => {
    const { t } = useTranslation();

    return (
        <Container sx={{ mt: 4, mb: 4 }}>
            <Paper style={{ height: "25vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                <Typography variant="h5" textAlign={'center'}>
                    {t("this_feature_is_disabled")}
                </Typography>
            </Paper>
        </Container>
    );
};

export default DisabledFeature;