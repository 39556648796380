import { Breakpoint } from '@mui/material';
import { isMobileOnly } from 'react-device-detect';
import MobileMyTerritoriesContent from './mobile/MobileMyTerritoriesContent';
import DesktopMyTerritoriesContent from './desktop/DesktopMyTerritoriesContent';

interface Props {
    size: Breakpoint;
}

const MyTerritoriesContent = (props: Props) => {
    if (isMobileOnly) {
        return <MobileMyTerritoriesContent />
    } else {
        return <DesktopMyTerritoriesContent size={props.size} />
    }
};

export default MyTerritoriesContent;