import * as React from 'react';
import { Container, LinearProgress, Paper, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../../stores';
import { Territory } from '../../../../domain/Territory';
import { getShapeCenter, stringToLatLng } from '../../mapUtils';
import ReturnTerritoryDialog from '../../dialogs/ReturnTerritoryDialog';
import { useOpenDialog } from '../../../../hooks/useOpenDialog';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import { useConfirm } from 'material-ui-confirm';
import ShowTerritoryDialog from '../../dialogs/ShowTerritoryDialog';
import MobileMyTerritoriesTableRow from './MobileMyTerritoriesTableRow';


const MobileMyTerritoriesContent = observer(() => {
    const { t } = useTranslation();
    const { serverStore, territoryStore, publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [myAssignments, setMyAssignments] = React.useState<TerritoryAssignment[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();
    const confirm = useConfirm();

    React.useEffect(() => {
        territoryStore.getMyTerritories((data) => {
            setMyAssignments(data)
        });
    }, [territoryStore, publisherStore.myPublisher]);


    const handleTerritoryDirections = (territory: Territory) => {
        const shape = stringToLatLng(territory.boundary);
        const center = getShapeCenter(shape);
        confirm({
            title: t("show_route"),
            description: `${t("do_you_have_google_maps_app")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`);
            // window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`);
        });
    }

    const openReturnTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("return-territory")
    }

    const openShowTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("show-territory")
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
    }

    const handleTerritoryReturned = () => {
        territoryStore.getMyTerritories((data) => {
            setMyAssignments(data)
        });
    }

    return (
        <>
            <Container sx={{ mt: 4, mb: 4 }}>
                <Paper style={{ height: "80vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                    {myAssignments.length ? <TableContainer>
                        <Table>
                            <TableBody>
                                {myAssignments
                                    .map((assignment: TerritoryAssignment, i: number) => (
                                        <MobileMyTerritoriesTableRow
                                            key={assignment.id}
                                            index={i+1}
                                            assignment={assignment}
                                            onTerritoryShow={openShowTerritoryDialog}
                                            onTerritoryDirections={handleTerritoryDirections}
                                            onTerritoryReturn={openReturnTerritoryDialog}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer> : (
                        <>
                            {serverStore.serverRequestLoading && <LinearProgress />}
                            <Typography variant="h6" textAlign={'center'}>
                                {t("you_dont_have_assigned_territories")}
                            </Typography>
                        </>

                    )}
                </Paper>
            </Container>
            <ReturnTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("return-territory")}
                onClose={handleCloseDialog}
                onTerritoryReturned={handleTerritoryReturned}
            />
            <ShowTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("show-territory")}
                onClose={handleCloseDialog}
            />
        </>
    );
});

export default MobileMyTerritoriesContent;