import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import { Territory } from '../../../../domain/Territory';
import { isMobileOnly } from 'react-device-detect';
import MobileTerritoryAssignmentTable from './MobileTerritoryAssignmentTable';
import DesktopTerritoryAssignmentTable from './DesktopTerritoryAssignmentTable';

interface Props {
    assignments: TerritoryAssignment[];
    onTerritoryShow: (territory: Territory) => void;
    onTerritoryAssign: (territory: Territory) => void;
    onAssignmentEdit: (assignment: TerritoryAssignment) => void;
}

const TerritoryAssignmentTable = (props: Props) => {

    if (isMobileOnly) {
        return <MobileTerritoryAssignmentTable
            assignments={props.assignments}
            onTerritoryShow={props.onTerritoryShow}
        />
    } else {
        return <DesktopTerritoryAssignmentTable
            assignments={props.assignments}
            onTerritoryShow={props.onTerritoryShow}
            onTerritoryAssign={props.onTerritoryAssign}
            onAssignmentEdit={props.onAssignmentEdit}
        />
    }
};

export default TerritoryAssignmentTable;