import PeopleIcon from '@mui/icons-material/People';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarSubMenuItem from '../SideBarSubMenuItem';
import SideBarMenuCollapseItem from '../SideBarMenuCollapseItem';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';

const SideBarCongregationMenu = observer(() => {
    const { t } = useTranslation();
    const { configurationStore } = useStores();
    const isLiteratureMenuEnabled = configurationStore.isLiteratureFeatureEnabled();
    
    return (
        <SideBarMenuCollapseItem icon={<PeopleIcon />} type="congregation" text={t("congregation")}>
            <Can do="read" on='Publishers'>
                <SideBarSubMenuItem
                    id="side_menu_item_publishers"
                    to="/publishers"
                    text={t("publishers")} />
            </Can>
            <Can do="read" on='Attendance'>
                <SideBarSubMenuItem
                    id="side_menu_item_attendance"
                    to="/attendance"
                    text={t("attendance")} />
            </Can>
            <Can do="read" on='Groups'>
                <SideBarSubMenuItem
                    id="side_menu_item_groups"
                    to="/groups"
                    text={t("groups")} />
            </Can>
            {isLiteratureMenuEnabled && <Can do="read" on='Literature'>
                <SideBarSubMenuItem
                    id="side_menu_item_literature"
                    to="/literature"
                    text={t("literature")} />
            </Can>}
            <Can do="read" on='CongregationState'>
                <SideBarSubMenuItem
                    id="side_menu_item_congregation_state"
                    to="/congregation-state"
                    text={t("congregation_state")} />
            </Can>
        </SideBarMenuCollapseItem>
    );
});

export default SideBarCongregationMenu;