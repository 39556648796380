import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { Accordion, AccordionDetails, AccordionSummary, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import MonthSwitcher from '../../components/MonthSwitcher';
import moment from 'moment';

const CongregationStateTab = observer(() => {
    const { t } = useTranslation();
    const { serverStore, congregationStateStore } = useStores();
    const [date, setDate] = React.useState<Date>(moment().startOf('month').toDate());
    const [state, setState] = React.useState<any[]>([]);
    const [currentState, setCurrentState] = React.useState<any>();

    React.useEffect(() => {
        congregationStateStore.getCongregationState((state: any[]) => {
            setState(state);
            const curState = state.find((s: any) => moment(s.date).isSame(date))
            setCurrentState(curState);
        });
    }, [congregationStateStore]);

    const handleDateChange = (date: Date) => {
        setDate(date);
        const curState = state.find((s: any) => moment(s.date).isSame(date))
        setCurrentState(curState);
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Accordion >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography component="span">{t("info")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{t("congregation_state_text")}</AccordionDetails>
                </Accordion>
                {serverStore.serverRequestLoading && <LinearProgress />}

                <br />
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <MonthSwitcher date={date} onDateUpdate={handleDateChange} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentState && (
                            <>
                                <TableRow>
                                    <TableCell><h4>{t("publishers")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("publishers")}</TableCell>
                                    <TableCell><b>{currentState.publishers}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("elders")}</TableCell>
                                    <TableCell>{currentState.elders}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("ministerial_servants")}</TableCell>
                                    <TableCell>{currentState.servants}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("baptised_publishers")}</TableCell>
                                    <TableCell>{currentState.baptisedPublishers}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("anointed_publishers")}</TableCell>
                                    <TableCell>{currentState.anointedPublishers}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("active_publishers")}</TableCell>
                                    <TableCell>{currentState.activePublishers}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("inactive_publishers")}</TableCell>
                                    <TableCell><b>{currentState.inactivePublishers}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("brothers")}</TableCell>
                                    <TableCell>{currentState.brothers}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("sisters")}</TableCell>
                                    <TableCell>{currentState.sisters}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("average_age")}</TableCell>
                                    <TableCell>{currentState.averageAge}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("average_baptised")}</TableCell>
                                    <TableCell>{currentState.averageBaptised}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>{t("service")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("pioneers")}</TableCell>
                                    <TableCell><b>{currentState.regularPioneers}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("auxiliary_pioneers")}</TableCell>
                                    <TableCell>{currentState.auxiliaryPioneers}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("special_pioneers")}</TableCell>
                                    <TableCell>{currentState.specialPioneers}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("bible_studies")}</TableCell>
                                    <TableCell><b>{currentState.bibleStudies}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("submitted_reports")}</TableCell>
                                    <TableCell>{currentState.submittedReports}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("no_report")}</TableCell>
                                    <TableCell>{currentState.emptyReports}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("service_groups")}</TableCell>
                                    <TableCell>{currentState.serviceGroups}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>{t("accounting")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("accounting_overall_amount")}</TableCell>
                                    <TableCell>{currentState.accountingOverallAmount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("accounting_income")}</TableCell>
                                    <TableCell>{currentState.accountingIncome}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("accounting_expenses")}</TableCell>
                                    <TableCell>{currentState.accountingExpenses}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>{t("attendance")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("average_attendance")}</TableCell>
                                    <TableCell>{currentState.averageAttendance}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>{t("literature")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("literature_requested_overall")}</TableCell>
                                    <TableCell>{currentState.literatureRequestedOverall}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>{t("territories")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("territories")}</TableCell>
                                    <TableCell>{currentState.territories}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("territories_taken")}</TableCell>
                                    <TableCell><b>{currentState.territoriesTaken}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("territories_returned")}</TableCell>
                                    <TableCell>{currentState.territoriesReturned}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>{t("schedule")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("students")}</TableCell>
                                    <TableCell>{currentState.students}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>{t("cart_witnessing")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("cart_records")}</TableCell>
                                    <TableCell>{currentState.cartRecords}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("cart_publishers_approved")}</TableCell>
                                    <TableCell>{currentState.cartPublishersApproved}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("cart_publishers_served")}</TableCell>
                                    <TableCell>{currentState.cartPublishersServed}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>{t("users")}</h4></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("users")}</TableCell>
                                    <TableCell>{currentState.users}</TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    );
});

export default CongregationStateTab;