import * as React from 'react';
import { Button, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { getLocale } from '../utils/localeUtils';

interface Props {
    date: Date,
    onDateUpdate: (date: any) => void;
}

const WeekSwitcher = (props: Props) => {
    const { t } = useTranslation();
    const [date, setDate] = React.useState(moment(props.date).startOf("week").toDate());

    React.useEffect(() => {
        setDate(moment(props.date).startOf("week").toDate());
    }, [props.date])

    const handleLeft = () => {
        const newDate = moment(date).subtract(1, "week").toDate();
        setDate(newDate);
        props.onDateUpdate(newDate);
    }

    const handleRight = () => {
        const newDate = moment(date).add(1, "week");
        setDate(newDate.toDate());
        props.onDateUpdate(newDate.toDate());
    }

    return (
        <div style={{ display: "flex", alignItems: "center"}}>
            <Button onClick={handleLeft}>{"<"}</Button>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                <DatePicker
                    label={t("date")}
                    value={date}
                    disableMaskedInput
                    onChange={value => {
                        props.onDateUpdate(value);
                    }}
                    renderInput={(params) => <TextField {...params} margin="normal" name="date" />}
                />
            </LocalizationProvider>
            <Button onClick={handleRight}>{">"}</Button>
        </div>
    )
};

export default WeekSwitcher;