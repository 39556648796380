import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { TerritoryGroup } from '../domain/TerritoryGroup';
import { TerritoryAssignment } from '../domain/TerritoryAssignment';
import { TerritoryRequest } from '../domain/TerritoryRequest';

export class TerritoryStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    groups: TerritoryGroup[] = [];
    myAssignments: TerritoryAssignment[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getMyTerritories = (callback: (data: any) => void) => {
        const myPublisher = this.rootStore.publisherStore.myPublisher;
        if (myPublisher) {
            this.serverStore.get(`/territory-assignment?publisherId=${myPublisher.id}`, action((data) => {
                this.myAssignments = data;
                callback(data);
            }));
        }
    }

    getAllGroups = (filter?: string, callback?: () => void) => {
        let url = '/territory-group';
        if (filter) {
            url = `${url}?filter=${filter}`;
        }
        this.serverStore.get(url, action((groups: TerritoryGroup[]) => {
            this.groups = groups;
            callback && callback();
        }));
    }

    searchGroups = (search: string) => {
        this.serverStore.get(`/territory-group?search=${search}`, action((groups: TerritoryGroup[]) => {
            this.groups = groups;
        }));
    }

    createTerritory = (obj: any) => {
        this.serverStore.post('territory', obj, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_created_successfully", "success");
        }))
    }

    updateTerritory = (id: number, obj: any) => {
        this.serverStore.put(`territory/${id}`, obj, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_updated_successfully", "success");
        }))
    }

    deleteTerritory = (id: number, upload: boolean) => {
        this.serverStore.delete(`/territory/${id}?upload=${upload}`, action(() => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_deleted_successfully", "success");
        }));
    }

    getAssignments = (id: number, callback: (data: any) => void) => {
        this.serverStore.get(`/territory-assignment?territoryId=${id}`, action((data) => {
            callback(data);
        }));
    }

    getActiveAssignments = (callback: (data: any) => void) => {
        this.serverStore.get("/territory-assignment?active=true", action((data) => {
            callback(data);
        }));
    }

    getPublisherAssignments = (publisherId: number, all: boolean, callback: (data: any) => void) => {
        let url = `/territory-assignment?publisherId=${publisherId}`;
        if (all) {
            url = `${url}&all=true`;
        }
        this.serverStore.get(url, action((data) => {
            callback(data);
        }));
    }

    updateTerritoryAssignment = (assignment: TerritoryAssignment, callback?: (data: any) => void) => {
        this.serverStore.put(`territory-assignment/${assignment.id}`, assignment, action((data) => {
            this.rootStore.notificationsStore.add("the_territory_assignment_was_updated_successfully", "success");
            callback && callback(data);
        }))
    }

    createTerritoryGroup = (obj: TerritoryGroup) => {
        this.serverStore.post('territory-group', obj, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_group_was_created_successfully", "success");
        }))
    }

    updateTerritoryGroup = (id: number, obj: TerritoryGroup) => {
        const { territories, ...rest } = obj;
        this.serverStore.put(`territory-group/${id}`, rest, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_group_was_updated_successfully", "success");
        }))
    }

    deleteTerritoryGroup = (id: number) => {
        this.serverStore.delete(`/territory-group/${id}`, action(() => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_group_was_deleted_successfully", "success");
        }));
    }

    isTerritoryAssigned = (territoryId: number, callback: (result: boolean) => void) => {
        this.serverStore.get(`territory-assignment/check?territoryId=${territoryId}`, action((result) => {
            callback(result)
        }))
    }

    assignTerritory = (territoryId: number, publisherId: number, notes?: string) => {
        this.serverStore.post('territory-assignment', { territoryId, publisherId, notes }, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_assigned_successfully", "success");
        }), () => {
            this.rootStore.notificationsStore.add("failed_to_assign_the_territory", "error");
        })
    }

    returnTerritory = (territoryId: number, unworked?: boolean, callback?: () => void) => {
        this.serverStore.post('territory-assignment/return', { territoryId, unworked }, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_returned_successfully", "success");
            callback && callback();
        }), () => {
            this.rootStore.notificationsStore.add("failed_to_return_the_territory", "error");
        })
    }

    deleteTerritoryAssignment = (id: number, callback?: () => void) => {
        this.serverStore.delete(`/territory-assignment/${id}`, action(() => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_assignment_was_deleted_successfully", "success");
            callback && callback();
        }));
    }

    getMyTerritoryRequests = (callback?: (requests: TerritoryRequest[]) => void) => {
        const publisherId = this.rootStore.userStore.userData.publisherId;
        if (publisherId) {
            this.getTerritoryRequests(publisherId, callback);
        }
    }

    getTerritoryRequests = (publisherId: number, callback?: (requests: TerritoryRequest[]) => void) => {
        this.serverStore.get(`territory-request?publisherId=${publisherId}`, action((requests: TerritoryRequest[]) => {
            callback && callback(requests);
        }));
    }

    requestTerritory = (territoryId: number, notes?: string, callback?: () => void) => {
        const publisher = this.rootStore.publisherStore.myPublisher;
        const dto = {
            territoryId,
            notes
        } as any
        if (publisher) {
            dto.publisherId = publisher.id
        }

        this.serverStore.post('territory-request', dto, action((data) => {
            this.rootStore.notificationsStore.add("the_territory_was_requested_successfully", "success");
            callback && callback();
        }), () => {
            this.rootStore.notificationsStore.add("failed_to_request_the_territory", "error");
        })
    }

    assignRequestedTerritory = (requestId: number, callback?: () => void) => {
        this.serverStore.put(`territory-request/${requestId}`, {}, action((data) => {
            this.rootStore.notificationsStore.add("the_territory_was_assigned_successfully", "success");
            callback && callback();
        }), () => {
            this.rootStore.notificationsStore.add("failed_to_assign_the_territory", "error");
        })
    }

    deleteTerritoryRequest = (id: number, callback?: () => void) => {
        this.serverStore.delete(`/territory-request/${id}`, action(() => {
            this.rootStore.notificationsStore.add("the_territory_request_was_deleted_successfully", "success");
            callback && callback();
        }));
    }

    getStatistics = (from: Date, to: Date, callback?: (statistics: any) => void) => {
        this.serverStore.get(`territory/statistics?from=${from.toISOString()}&to=${to.toISOString()}`, action((result: any) => {
            callback && callback(result);
        }));
    }

    getHistory = (page: number, pageSize: number, callback?: (assignments: TerritoryAssignment[]) => void) => {
        this.serverStore.get(`territory-assignment/history?page=${page}&pageSize=${pageSize}`, action((result: TerritoryAssignment[]) => {
            callback && callback(result);
        }));
    }

    refreshTerritoryAssignments = (months: number, callback?: () => void) => {
        this.serverStore.post(`territory-assignment/refresh?months=${months}`, {}, action(() => {
            callback && callback();
        }));
    }

    uploadJson = (file: any, callback?: () => void) => {
        this.serverStore.upload('/territory/upload', file[0], action(() => {
            this.rootStore.notificationsStore.add('json_is_uploaded', "success");
            this.getAllGroups();
            callback && callback();
        }));
    }
}