import { TableContainer, Table, TableBody, TableRow, TableCell, Paper, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { AccountingSummary } from '../../domain/AccountingSummary';

interface Props {
    summary?: AccountingSummary;
    year?: boolean;
}

const AccountingSummaryTable = (props: Props) => {
    const { t } = useTranslation();
    const summary = props.summary;

    const total = (key: string) => {
        if (summary) {
            return `${t("overall")} ${summary && (summary as any)[key].toFixed(2)}`
        }
        return '';
    }

    const startText = !!props.year ? "year_start" : "month_start";
    const endText = !!props.year ? "year_end" : "month_end";

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" > {`${t(startText)}: ${summary ? summary.startTotal.toFixed(2) : ''}`} </Typography>
            <br />
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6"> {t("income")} </Typography>
                            </TableCell>
                            <TableCell><b>{total("totalIncome")}</b></TableCell>
                        </TableRow>
                        {summary && Object.keys(summary.incomes)
                            .map((key) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{summary.incomes[key].toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6"> {t("expenses")} </Typography>
                            </TableCell>
                            <TableCell><b>{total("totalExpenses")}</b></TableCell>
                        </TableRow>
                        {summary && Object.keys(summary.expenses)
                            .map((key) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{summary.expenses[key].toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Typography variant="subtitle1" > {`${t("sent_for_worldwide_work")}: ${summary ? summary.worldwideSent.toFixed(2) : ''}`} </Typography>
            <Typography variant="h6" > {`${t("difference")}: ${summary ? summary.diff.toFixed(2) : ''}`} </Typography>
            <Typography variant="h5" > {`${t(endText)}: ${summary ? summary.endTotal.toFixed(2) : ''}`} </Typography>
        </Paper>
    );
};

export default AccountingSummaryTable;